
import {Component, Vue, Watch} from "vue-property-decorator";

import ChargePointForm from '@/components/ChargePointForm.vue';
import RouteDialog from "@/components/RouteDialog.vue";
import {
  BasicAuthStatus,
  CentralSystem,
  ChargePoint,
  ChargePointWithLinked,
  LinkedCentralSystem, NetworkProfile,
  RouteDialogConfirmEvent
} from "@/domain";
import {namespace} from "vuex-class";
import {BasicAuthDeviation} from "@/domain/basic-auth-deviation";

const centralSystemsModule = namespace('centralSystems');
const basicAuthDeviationsModule = namespace('basicAuthDeviations');
const networkProfilesModule = namespace('networkProfiles');

@Component({
  components: {RouteDialog, ChargePointForm},
  data: () => ({
    config: undefined,
  })
})
export default class EditChargePointDialog extends Vue {
  @centralSystemsModule.State('items')
  readonly availableCentralSystems!: CentralSystem[];

  @basicAuthDeviationsModule.State('items')
  readonly basicAuthDeviations!: BasicAuthDeviation[];

  @networkProfilesModule.State('items')
  readonly networkProfiles!: NetworkProfile[];

  chargePoint: Partial<ChargePointWithLinked & { changeBasicAuthPassword: boolean }> = {
    name: '',
    ocppVersion: '1.6',
    type: 'WEBSOCKET',
    basicAuthStatus: BasicAuthStatus.NOT_REQUIRED,
    changeBasicAuthPassword: false,
    basicAuthDeviationId: -1,
  };

  existingChargePoint: ChargePoint | undefined;

  loading: boolean = true;

  incorrectAmountOfMasterCentralSystems: boolean = false;

  selectedCentralSystems: LinkedCentralSystem[] = [];

  @Watch('selectedCentralSystems', {deep: true})
  updateSelectedCentralSystems(newCentralSystems: LinkedCentralSystem[]) {
    if (newCentralSystems.filter(cs => cs.type === 'MASTER').length == 1) {
      this.incorrectAmountOfMasterCentralSystems = false;
    }
  }

  async mounted() {
    await this.$store.dispatch("centralSystems/fetchAll");
    await this.$store.dispatch("basicAuthDeviations/fetchAll");
    await this.$store.dispatch("networkProfiles/fetchAll");
    await this.$store.dispatch('specific/getConfig').then(
        value => this.$data.config = value
    );
    this.existingChargePoint = {
      ...await this.$store.dispatch("chargePoints/fetchOne", this.$route.params.id)
    };
    this.chargePoint = {...this.existingChargePoint};
    console.log('current charge point', this.chargePoint);
    this.selectedCentralSystems = this.availableCentralSystems.map(cs => {
      const linkedCentralSystem = this.chargePoint.linkedCentralSystems!.find((linkedCentralSystem) => linkedCentralSystem.centralSystem === cs.id);
      return {
        id: cs.id,
        name: cs.name,
        type: linkedCentralSystem != null ? linkedCentralSystem.type : 'READONLY',
        customChargePointName: linkedCentralSystem != null ? linkedCentralSystem.customChargePointName : undefined,
        selected: linkedCentralSystem != null,
        commandsAllowed: linkedCentralSystem != null ? linkedCentralSystem.commandsAllowed : true,
        sessionAuthorizationAllowed: linkedCentralSystem != null ? linkedCentralSystem.sessionAuthorizationAllowed : false,
        forwardBasicAuth: linkedCentralSystem != null ? linkedCentralSystem.forwardBasicAuth : this.$data.config.forwardBasicAuthDefaultValue,
      } as LinkedCentralSystem;
    });
    this.loading = false;
  }

  async onConfirm(event: RouteDialogConfirmEvent) {
    if (this.selectedCentralSystems.filter(cs => cs.type === 'MASTER' && cs.selected == true).length != 1) {
      this.incorrectAmountOfMasterCentralSystems = true;
      event.cancel();
      return;
    }
    try {
      /* Exactly one central system is selected as MASTER, update charge point */
      this.incorrectAmountOfMasterCentralSystems = false;
      this.selectedCentralSystems.forEach((cs, index) => {
        this.$store.dispatch("specific/linkCentralStation", {
          id: this.chargePoint.id,
          centralStationName: cs.name,
          link: cs.selected,
          type: cs.type,
          customChargePointName: cs.customChargePointName,
          commandsAllowed: cs.commandsAllowed,
          sessionAuthorizationAllowed: cs.sessionAuthorizationAllowed,
          forwardBasicAuth: cs.forwardBasicAuth,
        });
      });

      let basicAuthPassword = this.existingChargePoint ? this.existingChargePoint.basicAuthPassword : null;
      if (this.chargePoint.changeBasicAuthPassword) {
        basicAuthPassword = this.chargePoint.basicAuthPassword;
      }

      const payload: ChargePoint = {
        id: this.chargePoint.id!,
        name: this.chargePoint.name!,
        ocppVersion: this.chargePoint.ocppVersion!,
        type: this.chargePoint.type!,
        basicAuthStatus: this.chargePoint.basicAuthStatus!,
        basicAuthPassword: basicAuthPassword,
        uri: this.chargePoint.uri!,
        useManualURI: this.chargePoint.useManualURI!,
        useRequestHeader: this.chargePoint.useRequestHeader!,
        omitSoapHeader: this.chargePoint.omitSoapHeader!,
        basicAuthDeviationId: this.chargePoint.basicAuthDeviationId === -1 ? null : this.chargePoint.basicAuthDeviationId,
        networkProfileId: this.chargePoint.networkProfileId,
      };
      await this.$store.dispatch("chargePoints/updateOne", payload)
          // Refetch so store is updated with a charge point that contains linked central systems.
          // Otherwise that data is lost from the store until a refresh.
          // Simpler than updating the backend, due to the separate LCS linking and charge point update calls...
          .finally(() => this.$store.dispatch("chargePoints/fetchOne", this.$route.params.id));

      event.done();
    } catch (e) {
      console.log("An error has occurred while updating: ", e);
      event.cancel();
    }
  }

}
